import 'stylesheets/openlayers.css';

import 'maps/denmark-epsg-25832.geojson';
import 'maps/faroe-islands-epsg-32629.geojson';
import 'maps/greenland-epsg-32624.geojson';
import 'maps/northerneurope-epsg-25832.geojson';

import { ArticleMap } from 'javascripts/article-map.js';
import { HeroMap } from 'javascripts/hero-map.js';

init();

function init() {
  const mapElements = document.querySelectorAll('.map__wrapper');
  Array.prototype.forEach.call(mapElements, initArticleMap);

  const heroMapElements = document.querySelectorAll('.hero-map__wrapper');
  Array.prototype.forEach.call(heroMapElements, initHeroMap);
}

function initArticleMap(mapElement) {
  const lat = parseFloat(mapElement.dataset.latitude);
  const lon = parseFloat(mapElement.dataset.longitude);
  const geoJsonUrl = mapElement.dataset.geoJsonUrl;
  const featureKey = mapElement.dataset.featureKey;
  const featureValue = mapElement.dataset.featureValue;
  const pinIconUrl = mapElement.dataset.pinIconUrl;
  const mapConfig = mapElement.dataset.mapConfig;

  let position = undefined;
  if (!isNaN(lat) && !isNaN(lon)) {
    position = { lat, lon };
  }

  let highlightedFeature = undefined;
  if (featureKey && featureValue) {
    highlightedFeature = {
      key: featureKey,
      value: featureValue
    };
  }

  if (position == null && highlightedFeature == null ||
      geoJsonUrl == null) {
    return;
  }

  const map = new ArticleMap({
    element: mapElement,
    geoJsonUrl,
    position,
    highlightedFeature,
    pinIconUrl,
    mapConfigName: mapConfig
  });
  map.show();
}

function initHeroMap(mapElement) {
  const zoomedFeatureName =
      document.getElementById(mapElement.dataset.featureNameId);
  const map = new HeroMap({
    element: mapElement,
    mainGeoJsonUrl: mapElement.dataset.mainGeoJsonUrl,
    secondaryGeoJsonUrl: mapElement.dataset.secondaryGeoJsonUrl,
    position: {
      lat: parseFloat(mapElement.dataset.latitude),
      lon: parseFloat(mapElement.dataset.longitude)
    },
    pinIconUrl: mapElement.dataset.pinIconUrl,
    userIconUrl: mapElement.dataset.userIconUrl,
    zoomedFeatureName,
    taxonomyId: mapElement.dataset.taxonomyId,
    mapConfigName: mapElement.dataset.mapConfig
  });
  map.show();
}
