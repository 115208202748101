import proj4 from 'proj4';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import * as OlProjProj4 from 'ol/proj/proj4';
import * as OlProj from 'ol/proj';
import OlTilegridWMTS from 'ol/tilegrid/WMTS';
import OlSourceCluster from 'ol/source/Cluster';
import OlSourceVector from 'ol/source/Vector';
import OlFormatGeoJSON from 'ol/format/GeoJSON';
import OlLayerTile from 'ol/layer/Tile';
import OlLayerVector from 'ol/layer/Vector';
import OlStyleStyle from 'ol/style/Style';
import OlStyleCircle from 'ol/style/Circle';
import OlStyleFill from 'ol/style/Fill';
import OlStyleIcon from 'ol/style/Icon';
import OlStyleStroke from 'ol/style/Stroke';
import OlStyleText from 'ol/style/Text';
import OlOverlay from 'ol/Overlay';
import * as OlExtent from 'ol/extent';
import OlGeomPoint from 'ol/geom/Point';
import OlFeature from 'ol/Feature';
import OlControlZoom from 'ol/control/Zoom';
import OlControlControl from 'ol/control/Control';
import OlInteractionDoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import OlInteractionDragPan from 'ol/interaction/DragPan';
import OlInteractionMouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import OlInteractionPinchZoom from 'ol/interaction/PinchZoom';

import { getMapConfig, createTileSource } from 'javascripts/map-utils';

proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs'); //UTM for Denmark
proj4.defs('EPSG:32629', '+proj=utm +zone=29 +ellps=WGS84 +units=m +no_defs'); //UTM for Faroe Islands
proj4.defs('EPSG:32624', '+proj=utm +zone=24 +ellps=WGS84 +units=m +no_defs'); //UTM for Greenland
OlProjProj4.register(proj4);

class LocationControl extends OlControlControl {
  constructor(heroMap, opt_options) {
    var options = opt_options || {};
    var button = document.createElement('button');
    button.innerHTML = '<svg viewBox="-4 -4 32 32" preserveAspectRatio="xMidYMid meet" focusable="false" class="style-scope iron-icon" style="fill: currentColor; pointer-events: none; display: block; width: 100%; height: 100%;"><g class="style-scope iron-icon"><path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" class="style-scope iron-icon"></path></g></svg>'
    var element = document.createElement('div');
    element.className = 'hero-map__location-control ol-unselectable ol-control';
    element.appendChild(button);
    super({
      element: element,
      target: options.target
    });
    this.heroMap = heroMap;
    button.addEventListener('click', this.goToCurrentPosition.bind(this), false);
  }

  goToCurrentPosition() {
    var heroMap = this.heroMap;
    if (heroMap.userLocationSource.getFeatures().length > 0) {
      heroMap.userLocationSource.clear();
      return;
    }
    heroMap.hoveredFeature = null;
    navigator.geolocation.getCurrentPosition(function (position) {
      var coords = OlProj.fromLonLat([position.coords.longitude, position.coords.latitude], heroMap.projectionCode);
      var point = new OlGeomPoint(coords);
      var feature = new OlFeature(point);
      feature.setProperties({ title: "Du er her" })
      heroMap.userLocationSource.addFeature(feature)
      var features = heroMap.mainVectorLayer.getSource().getFeaturesAtCoordinate(coords);
      if (features.length > 0) {
        heroMap.hoveredFeature = { name: features[0].values_.KOMNAVN, slug: features[0].values_.SLUG ?? features[0].values_.KOMNAVN };
        heroMap._zoomToFeature(null, OlExtent.buffer(OlExtent.boundingExtent([coords]), 3000));
        return;
      }
      heroMap.view.animate({
        center: coords,
        zoom: 5
      })
    })
  }
}

export class HeroMap {
  constructor({
    element,
    mainGeoJsonUrl,
    secondaryGeoJsonUrl,
    position,
    pinIconUrl,
    userIconUrl,
    zoomedFeatureName,
    taxonomyId,
    mapConfigName
  }) {
    this.element = element;
    this.mapConfig = getMapConfig(mapConfigName);
    this.extent = this.mapConfig.extent;
    this.projectionCode = this.mapConfig.projectionCode;
    this.projection = OlProj.get(this.projectionCode);
    this.position = position;
    this.hoveredFeature = null;
    this.zoomAtFeature = 0;
    this.popupContainer =
        this.element.parentNode.querySelector('.hero-map__popup');
    this.popupContent =
        this.popupContainer.querySelector('.hero-map__popup-content');
    this.view = null;
    this.pinIconUrl = pinIconUrl;
    this.userIconUrl = userIconUrl;
    this.isZoomed = false;
    this.isZooming = false;
    this.zoomedFeatureNameElement = zoomedFeatureName;
    this.taxonomyId = taxonomyId;
    this.tileSource = this.mapConfig.layer; // Unused?
    
    
    this.projection.setExtent(this.extent);

    this.tileLayer = new OlLayerTile({
      source: createTileSource(this.mapConfig),
      name: this.mapConfig.layer,
      title: 'Skærmkort dæmpet',
      visible: false
    });

    if (secondaryGeoJsonUrl) {
      this.secondaryVectorLayer = new OlLayerVector({
        source: new OlSourceVector({
          format: new OlFormatGeoJSON(),
          url: secondaryGeoJsonUrl
        }),
        style: new OlStyleStyle({
          fill: new OlStyleFill({
            color: 'rgba(210, 210, 210, 1)'
          })
        }),
        updateWhileAnimating: true,
        updateWhileInteracting: true
      });
    }

    this.mainVectorLayer = new OlLayerVector({
      source: new OlSourceVector({
        format: new OlFormatGeoJSON(),
        url: mainGeoJsonUrl
      }),
      style: this._getMainLayerStyle.bind(this),
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    this.locationsVectorSource = new OlSourceVector();
    this.locationsClusterSource = new OlSourceCluster({
      distance: 40,
      source: this.locationsVectorSource
    });
    this.locationsLayer = new OlLayerVector({
      source: this.locationsClusterSource,
      style: this._getLocationsLayerStyle.bind(this)
    });

    this.userLocationSource = new OlSourceVector();
    this.userLocationLayer = new OlLayerVector({
      source: this.userLocationSource,
      style: new OlStyleStyle({
        image: new OlStyleIcon({
          anchor: [0.5, 0.5],
          src: this.userIconUrl
        })
      }),
      updateWhileAnimating: true,
      updateWhileInteracting: true
    })
  }

  show() {
    const { lat, lon } = this.position;
    this.focusPosition = OlProj.fromLonLat([lon, lat], this.projectionCode);

    this.view = new OlView({
      center: this.focusPosition,
      zoom: 1.4,
      resolutions: this.mapConfig.tileGrid?.getResolutions(),
      projection: this.projection,
      extent: this.extent,
      constrainOnlyCenter: true
    });

    this.view.on('change:resolution', this._onResolutionChange.bind(this));

    this.overlay = new OlOverlay({
      element: this.popupContainer
    });
    this._setupPopupCloseButton();

    var locationControl = new LocationControl(this);
    var controls = [new OlControlZoom({})];
    if (navigator.geolocation) {
      controls.push(locationControl);
    }

    this.map = new OlMap({
      target: this.element,
      layers: [
        this.tileLayer,
        this.secondaryVectorLayer,
        this.mainVectorLayer,
        this.locationsLayer,
        this.userLocationLayer
      ].filter(layer => layer),
      view: this.view,
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
      interactions: this._getMapInteractions(),
      overlays: [this.overlay],
      controls: controls
    });

    this.view.fit(this.extent, {
      constrainResolution: false,
      duration: 0,
      padding: [165, 5, 5, 5]
    });

    this._addMapEventHandlers();

    // Attempts to zoom in on the users location on entering the page.
    if (this.mainVectorLayer.getSource().getFeatures().length > 0) {
      locationControl.goToCurrentPosition();
    } else {
      this.mainVectorLayer.getSource().on('featuresloadend', function() {
        locationControl.goToCurrentPosition();
      });
    }
  }

  _getMainLayerStyle(feature, resolution) {
    const featureValue = feature.getProperties().KOMNAVN;
    const hoveredFeatureValue = this.hoveredFeature?.name;
    const isSelected = (featureValue == hoveredFeatureValue); // Hovered/clicked
    const fillColor = isSelected ? `rgba(255, 255, 255, ${this.isZoomed ? 0 : 1})` : 'rgba(49, 89, 137, 1)';
    return new OlStyleStyle({
      fill: new OlStyleFill({
        color: fillColor
      }),
      stroke: new OlStyleStroke({
        color: isSelected ? 'rgba(255, 255, 255, 0)' :
            'rgba(255, 255, 255, .5)'
      }),
      text: (resolution < 256 && !isSelected) ? new OlStyleText({
        text: featureValue,
        font: '16px Publico text',
        fill: new OlStyleStroke({
          color: 'white'
        }),
        stroke: new OlStyleStroke({
          color: 'rgba(49, 89, 137, 1)',
          width: 10
        })
      }) : null
    });
  }

  _getLocationsLayerStyle(feature, resolution) {
    if (feature.get('features')) {
      const size = feature.get('features').length;
      if (size > 1) {
        return new OlStyleStyle({
          image: new OlStyleCircle({
            radius: 14,
            fill: new OlStyleFill({
              color: '#ea4335'
            })
          }),
          text: new OlStyleText({
            offsetY: 1,
            text: size.toString(),
            font: '12px sans-serif',
            fill: new OlStyleFill({
              color: '#fff'
            })
          })
        });
      }
    }

    return new OlStyleStyle({
      image: new OlStyleIcon({
        anchor: [0.5, 1],
        src: this.pinIconUrl
      })
    });
  }

  _handlePointerMove(evt) {
    if (evt.originalEvent.pointerType == 'touch' || evt.dragging) {
      return;
    }

    const mainFeature = this._getMainFeatureNameAndSlugAtPixel(evt.pixel);

    let cursor = '';
    if (mainFeature) {
      cursor = 'pointer';
    } else if (this.isZoomed) {
      const locationFeature = this._getLocationAtPixel(evt.pixel);
      if (locationFeature) {
        cursor = 'pointer';
      }
    }
    this.element.style.cursor = cursor;

    if (this._popupIsLocked() || this.isZoomed || this.isZooming) {
      return;
    }

    this.hoveredFeature = mainFeature;
    this.mainVectorLayer.changed();

    if (mainFeature) {
      this._updatePopupContentForFeature(mainFeature, false);
      this._movePopup(evt.coordinate, evt.pixel, false);
    } else {
      this._hidePopup();
    }
  }

  _handleClick(evt) {
    const mainFeature = this._getMainFeatureNameAndSlugAtPixel(evt.pixel);

    if (this.isZoomed) {
      const locationInfo = this._getLocationAtPixel(evt.pixel);
      if (locationInfo) {
        const features = locationInfo.get('features') || [locationInfo]
        if (features.length == 1) {
          this._updatePopupContentForLocationFeature(features[0]);
          this._movePopup(evt.coordinate, evt.pixel, true);
        } else {
          this.view.animate({
            center: evt.coordinate,
            zoom: this.view.getZoom() + 2
          })
        }
      } else if (mainFeature &&
          mainFeature?.name != this.hoveredFeature?.name) {
        this.hoveredFeature = mainFeature;
        this._zoomToFeature();
      }
      return;
    }

    this.hoveredFeature = mainFeature;
    this.mainVectorLayer.changed();

    if (!mainFeature) {
      this._hidePopup();
      return;
    }

    this._updatePopupContentForFeature(mainFeature, true);
    this._movePopup(evt.coordinate, evt.pixel, true);
  }

  _getMainFeatureNameAndSlugAtPixel(pixel) {
    let feature = null;
    this.map.forEachFeatureAtPixel(pixel, function (f) {
      feature = f;
    }, {
      layerFilter: (layer) => { return layer == this.mainVectorLayer; }
    });
    if (feature == null) {
      return null;
    }
    return  { 
      name: feature.getProperties().KOMNAVN,
      slug: feature.getProperties().SLUG ?? feature.getProperties().KOMNAVN
    };
  }

  _getLocationAtPixel(pixel) {
    let feature = null;
    this.map.forEachFeatureAtPixel(pixel, function (f) {
      feature = f;
    }, {
      layerFilter: (layer) => { return layer == this.locationsLayer; }
    });
    return feature;
  }

  _updatePopupContentForFeature(featureValue, showNavigation) {
    while (this.popupContent.firstChild) {
      this.popupContent.removeChild(this.popupContent.firstChild);
    }
    const nameElement = document.createElement('div');
    nameElement.className = 'hero-map__popup-name';
    nameElement.textContent = featureValue.name;
    this.popupContent.appendChild(nameElement);
    if (showNavigation) {
      if (this._isFrontpageMap()) {
        const featureLink = document.createElement('a');
        featureLink.className = 'hero-map__popup-link';
        const slug = featureValue.slug.replace(/ /g, '_');
        featureLink.href = '/' + encodeURIComponent(slug);
        featureLink.textContent = 'Læs artiklen';
        const linkContainer = document.createElement('div');
        linkContainer.appendChild(featureLink);
        this.popupContent.appendChild(linkContainer);
      }
      if (featureValue.SHOWPLACES !== false) {
        const locationsButton = document.createElement('button');
        locationsButton.className = 'hero-map__popup-link';
        locationsButton.type = 'button';
        locationsButton.textContent = this.mapConfig.showPlacesText;
        locationsButton.addEventListener('click', this._zoomToFeature.bind(this));
        this.popupContent.appendChild(locationsButton);
      }
    }
  }

  _updatePopupContentForLocationFeature(feature) {
    while (this.popupContent.firstChild) {
      this.popupContent.removeChild(this.popupContent.firstChild);
    }
    const props = feature.getProperties();
    const nameElement = document.createElement('div');
    nameElement.className = 'hero-map__popup-name';
    nameElement.textContent = props.name;
    if (props.clarification) {
      nameElement.textContent += ' - ' + props.clarification;
    }
    const locationLink = document.createElement('a');
    locationLink.className = 'hero-map__popup-link';
    locationLink.href = props.url;
    locationLink.textContent = 'Læs artiklen';
    this.popupContent.appendChild(nameElement);
    this.popupContent.appendChild(locationLink);
  }

  _setupPopupCloseButton() {
    const closeButton =
        this.popupContainer.querySelector('.hero-map__popup-closer');
    closeButton.addEventListener('click', () => {
      this._hidePopup();
      closeButton.blur();

      if (!this.isZoomed) {
        this.hoveredFeature = null;
        this.mainVectorLayer.changed();
      }
    });
  }

  _zoomToFeature(evt, givenExtent) {
    this.tileLayer.setVisible(true);
    this.isZooming = true;
    this.popupContainer.classList.add('hidden');
    const { extent, features } =
        this._getExtentAndFeaturesWithValue(this.hoveredFeature.name);
    const center = OlExtent.getCenter(extent);
    this.locationsVectorSource.clear();
    this.locationsLayer.changed();
    this.map.renderSync();

    this._setZoomedFeatureName(this.hoveredFeature.name);

    this.view.fit(givenExtent ? givenExtent : extent, {
      constrainResolution: false,
      duration: 1000,
      padding: [givenExtent ? 50 : 200, 50, 50, 50],
      callback: () => {
        this.isZooming = false;
        this.isZoomed = true;
        this.mainVectorLayer.changed(); // Recalculate fill
        this.zoomAtFeature = this.view.getZoom();
        this._showLocations(features);
      }
    });
  }

  _getExtentAndFeaturesWithValue(name) {
    const features = [];
    let extent = OlExtent.createEmpty();
    this.mainVectorLayer.getSource().forEachFeature((feature) => {
      if (feature.values_.KOMNAVN == name) {
        extent = OlExtent.extend(extent, feature.getGeometry().getExtent());
        features.push(feature);
      }
    });
    return { extent, features };
  }

  _showLocations(featureObjects) {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('readystatechange', () => {
      if (!(xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200)) {
        return;
      }
      const locationFeatures = [];
      let locationData = [];
      try {
        locationData = JSON.parse(xhr.responseText);
      } catch (e) {
        console.err(`hero-map failed to parse JSON: ${e}`);
      }
      locationData.forEach(data => {
        const coords = OlProj.fromLonLat([data.longitude, data.latitude],
            this.projectionCode);
        const point = new OlGeomPoint(coords);
        const feature = new OlFeature(point);
        feature.setProperties({ name: data.name, url: data.url, clarification: data.clarification });
        locationFeatures.push(feature);
      });
      this.locationsVectorSource.addFeatures(locationFeatures);
    });
    const getParameter = encodeURIComponent(this.hoveredFeature.name);
    const taxonomy = this._isTaxonomyMap() ? `&taxonomy=${this.taxonomyId}` : '';
    xhr.open('GET', `/api/map/v1/featurePoints?index=${getParameter}${taxonomy}`, true);
    xhr.send();
  }

  _zoomToTopLevel() {
    this.tileLayer.setVisible(false);
    this.locationsVectorSource.clear();
    this.locationsLayer.changed();
    this.map.renderSync();
    this._setZoomedFeatureName('');
    this._hidePopup();
    this.hoveredFeature = null;
    this.mainVectorLayer.changed();
  }

  _onResolutionChange() {
    if (this.view.getZoom() >= 9 &&
        this.locationsLayer.getSource() != this.locationsVectorSource) {
      this.locationsLayer.setSource(this.locationsVectorSource);
    } else if (this.view.getZoom() < 9 &&
        this.locationsLayer.getSource() != this.locationsClusterSource) {
      this.locationsLayer.setSource(this.locationsClusterSource);
    }
  }

  _setZoomedFeatureName(name) {
    this.zoomedFeatureNameElement.textContent = name;
  }

  _getMapInteractions() {
    const interactions = [];
    interactions.push(new OlInteractionDoubleClickZoom());
    interactions.push(new OlInteractionMouseWheelZoom());
    interactions.push(new OlInteractionDragPan());
    interactions.push(new OlInteractionPinchZoom());
    return interactions;
  }

  _addMapEventHandlers() {
    this.map.on('pointermove', this._handlePointerMove.bind(this));

    /* Avoid issues with e.g. map interactions happening while search field is
     * still focused (and virtual keyboard open on touch devices). Might be
     * better to ensure map is focused instead, once tabindex is added for
     * accessibility (though requires inclusion of, or workaround for, missing
     * bugfix https://github.com/openlayers/openlayers/pull/10291)
     */
    this.map.on('pointerdown', () => {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    });

    this.map.on('click', this._handleClick.bind(this));

    this.map.on('moveend', () => {
      const maxZoomedInLevel = this.zoomAtFeature * 0.8;
      if (this.view.getZoom() < maxZoomedInLevel && this.isZoomed) {
        this.isZoomed = false;
        this._zoomToTopLevel();
      }
    });
  }

  _movePopup(coordinate, pixel, shouldLockPosition) {
    this.overlay.setPosition(coordinate);
    this.popupContainer.classList.remove('hidden');
    if (shouldLockPosition) {
      this.popupContainer.classList.add('locked');

      if (pixel) {
        const popupWidth = this.popupContainer.clientWidth || 300;
        const popupHeight = this.popupContainer.clientHeight || 200;
        const verticalPopupOffset =
            parseInt(getComputedStyle(this.popupContainer).bottom) || 50;
        if (pixel[0] < popupWidth/2 ||
            pixel[0] > this.map.getSize()[0] - popupWidth/2 ||
            pixel[1] < popupHeight + verticalPopupOffset) {
          const desiredX = pixel[0];
          const desiredY = pixel[1] - (popupHeight + verticalPopupOffset)/2;
          this.view.animate({
            center: this.map.getCoordinateFromPixel([desiredX, desiredY])
          });
        }
      }
    } else {
      this.popupContainer.classList.remove('locked');
    }
  }

  _hidePopup() {
    this.popupContainer.classList.remove('locked');
    this.popupContainer.classList.add('hidden');
  }

  _popupIsLocked() {
    return this.popupContainer.classList.contains('locked');
  }

  _isFrontpageMap() {
    return !this.taxonomyId;
  }

  _isTaxonomyMap() {
    return !!this.taxonomyId;
  }
}
